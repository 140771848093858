<style>
.input-readonly>div>.vs-inputx {
	background-color: #e6e6e6;
}

.bg-dark {
	background-color: #e6e6e6 !important;
}
</style>
<template>
	<div class="vx-col w-5/6 ml-auto mr-auto">
		<span>
			<h4 class="mb-5">Shipment Cost Settlement</h4>
		</span>

		<!-- <vs-divider style="width: 100%; margin-left: 2%">Adjustment</vs-divider> -->
		<div class="vx-row mb-2">
			<div class="vx-col sm:w-1/2 w-full">
				<vs-input class="w-full input-readonly" label="Advance Cash Doc" name="Advance Cash Doc"
					v-model="create.advanceCashDoc" readonly />
			</div>
			<div class="vx-col sm:w-1/2 w-full">
				<label class="vs-input--label">Advance Cash Date</label>
				<datepicker :class="{
						'w-full': true,
						'border-danger': errors.has('postingDate'),
					}" v-model="create.postingDate" v-validate="'required'" name="postingDate" placeholder="Posting Date"
					:disabled-dates="{ from: new Date() }" :danger="errors.has('postingDate')" disabled>
				</datepicker>
				<!-- display: block -->
				<span v-show="errors.has('postingDate')" class="text-danger text-sm help is-danger">
					{{ errors.first("postingDate") }}
				</span>
			</div>
		</div>
		<div class="vx-row mb-2">
			<div class="vx-col sm:w-1/2 w-full">
				<vs-input name="cashValue" v-model="create.cashValue" class="w-full input-readonly"
					@keypress="globalIsNumber($event)" @keyup="
						create.cashValue = formatPrice(
							create.cashValue.toString()
						)
						" label="Advance Cash Value" placeholder="Advance Cash Value" :danger="errors.has('cashValue')" readonly />
				<!-- display: block -->
				<span v-show="errors.has('adjustmentValue')" class="text-danger text-sm help is-danger">
					{{ errors.first("adjustmentValue") }}
				</span>
			</div>
		</div>
		<vs-divider style="width: 100%; margin-left: 2%">Accounting Data</vs-divider>
		<div class="vx-row mb-2">
			<div class="vx-col sm:w-1/2 w-full">
				<vs-input class="w-full input-readonly" label="Transaction Reference" name="Transaction Reference"
					v-model="create.transactionRef" readonly />
			</div>
			<div class="vx-col sm:w-1/2 w-full">
				<label class="vs-input--label">Transaction Date</label>
				<datepicker :class="{
						'w-full': true,
						'border-danger': errors.has('transactionDate'),
					}" v-model="create.transactionDate" v-validate="'required'" name="transactionDate" placeholder="Transaction Date"
					:disabled-dates="{ from: new Date() }" :danger="errors.has('transactionDate')" disabled>
				</datepicker>
				<!-- display: block -->
				<span v-show="errors.has('transactionDate')" class="text-danger text-sm help is-danger">
					{{ errors.first("transactionDate") }}
				</span>
			</div>
		</div>
		<div class="vx-row mb-2">
			<div class="vx-col sm:w-1/2 w-full">
				<vs-input name="transactionValue" v-model="create.transactionValue" class="w-full input-readonly"
					@keypress="globalIsNumber($event)" @keyup="
						create.transactionValue = formatPrice(
							create.transactionValue.toString()
						)
						" label="Transaction Value" placeholder="Transaction Value" :danger="errors.has('transactionValue')" readonly />
				<!-- display: block -->
				<span v-show="errors.has('transactionValue')" class="text-danger text-sm help is-danger">
					{{ errors.first("transactionValue") }}
				</span>
			</div>
		</div>
		<vs-divider style="width: 100%; margin-left: 2%">Settlement</vs-divider>
		<div class="vx-row mb-2">
			<div class="vx-col sm:w-1/2 w-full">
				<vs-input class="w-full input-readonly" label="Settlement Doc" name="Settlement Doc"
					v-model="create.settlementDoc" readonly />
			</div>
			<div class="vx-col sm:w-1/2 w-full">
				<label class="vs-input--label">Settlement Date</label>
				<datepicker :class="{
						'w-full': true,
						'border-danger': errors.has('settlementDate'),
					}" v-model="create.settlementDate" name="settlementDate" placeholder="Settlement Date"
					:disabled-dates="{ from: new Date() }" :danger="errors.has('settlementDate')">
				</datepicker>
				<!-- display: block -->
				<span v-show="errors.has('settlementDate')" class="text-danger text-sm help is-danger">
					{{ errors.first("settlementDate") }}
				</span>
			</div>
		</div>

		<div class="vx-row mb-2">
			<div class="vx-col sm:w-1/2 w-full">
				<label class="vs-input--label">Total Realisasi</label>
				<!-- <span class="w-5/5">{{ inputs[indextr].value }} </span> -->
				<vs-input class="w-full input-readonly" name="Value" v-model="total" readonly />
			</div>
		</div>

		<vs-divider style="width: 100%; margin-left: 2%">Detail</vs-divider>
		<div class="vx-row mb-2">
			<div class="vx-col sm:w-1/1 w-full">
				<!-- <vs-table :sst="true" border :data="[1]">
					<template slot="thead">
						<vs-th>Expense Type</vs-th>
						<vs-th>Value</vs-th>
						<vs-th>Evidence</vs-th>
						<vs-th>Reason</vs-th>
						<vs-th>DO Number</vs-th>
						<vs-th></vs-th>
					</template> -->

				<template>
					<div v-for="(tr, indextr) in inputs" v-bind:key=indextr>
						<!-- <vs-tr > -->
						<!-- <vs-td> -->

						<div class="vx-row mb-2">
							<div class="vx-col sm:w-1/4 w-1/4">
								<label class="vs-input--label">Expense Type</label>
								<multiselect class="test" v-model="inputs[indextr].expense_type"
									:options="option.expenseType" :multiple="false" :allow-empty="false"
									:group-select="false" :max-height="160" :option-height="40" :limit="4"
									placeholder="" track-by="code" label="Code" :select-label="''">
									<template slot="singleLabel" slot-scope="dt">
										<span class="option__desc">
											<span class="option__title">{{ dt.option.code }} - {{ dt.option.description
												}}</span>
										</span>
									</template>

									<template slot="option" slot-scope="dt">
										<div class="option__desc">
											<span class="option__title">{{ dt.option.code }} - {{ dt.option.description
												}}</span>
										</div>
									</template>
								</multiselect>
							</div>
							<div class="vx-col sm:w-1/4 w-1/4">
								<label class="vs-input--label">Value</label>
								<!-- <span class="w-5/5">{{ inputs[indextr].value }} </span> -->
								<vs-input 
									class="w-full" 
									name="Value" 
									@keypress="globalIsNumber($event)" 
									@keyup="formatAndSetPrice($event, indextr)"
									v-model="inputs[indextr].displayValue"
								/>
							</div>
							<div class="vx-col sm:w-1/4 w-1/4">
								<label class="vs-input--label">Customer</label>
								<multiselect class="selectExample" v-model="inputs[indextr].customer_number"
									:options="option.customer" :multiple="false" :allow-empty="false"
									:group-select="false" :max-height="160" :limit="4" placeholder=""
									track-by="customer_name" label="customer_name" :select-label="''">
									<template slot="singleLabel" slot-scope="dt">
										<span class="option__desc">
											<span class="option__title">{{ dt.option.customer_code }} - {{ dt.option.customer_name }} </span>
										</span>
									</template>

									<template slot="option" slot-scope="dt">
										<div class="option__desc">
											<span class="option__title">{{ dt.option.customer_code }} - {{ dt.option.customer_name }} </span>
										</div>
									</template>
								</multiselect>
							</div>
							<div class="vx-col sm:w-1/4 w-1/4">
								<label class="vs-input--label">Evidence</label>
								<vs-input class="w-full" name="Value" v-model="inputs[indextr].evidence_text" />
							</div>
						</div>
						<div class="vx-row mb-2">
							<div class="vx-col sm:w-1/2 w-1/2">
								<div class="vx-col sm:w-1/3 w-full">
									<span>Upload file</span>
								</div>
								<div class="vx-col sm:w-2/3 w-full">
									<div class="vx-col sm:w-4/5 w-full">
										<input id="fileInput" @change="handleFileUpload(indextr, $event)" class="w-full inputx" type="file"
											multiple accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx" />
									</div>
								</div>
							</div>
							<div class="vx-col sm:w-1/2 w-1/2">
								<div class="whitespace-no-wrap">
									<div class="mt-4 flex mr-1">
										<vs-button style="margin-right: 5px; margin-bottom: 12px;" size="small"
											@click="removeField(indextr)" icon-pack="feather" icon="icon-minus"
											v-show="indextr || (!indextr && inputs.length > 1)">
										</vs-button>
										<vs-button size="small" @click="addField(indextr)" icon-pack="feather"
											icon="icon-plus" v-show="indextr == inputs.length - 1">
										</vs-button>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="vx-row mb-3 mt-6 w-4/4" v-if="inputs[indextr].evidence_text" style="width: 100%; margin-left: 0%">
							<div class="vx-col sm:w-1/3 w-full">
								<span>Upload file</span>
							</div>
							<div class="vx-col sm:w-2/3 w-full">
								<div class="vx-col sm:w-4/5 w-full">
									<input id="fileInput" name="file" class="w-full inputx" type="file" ref="file"
									multiple="multiple" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx" />
								</div>
							</div>
						</div> -->
					</div>
				</template>
				<!-- </vs-table> -->
			</div>
		</div>

		<!-- <div class="vx-row mb-2">
			<div class="vx-col sm:w-1/4 w-1/4"></div>
			<div class="vx-col sm:w-1/4 w-1/4"></div>
			<div class="vx-col sm:w-1/4 w-1/4"></div>
			<div class="vx-col sm:w-1/4 w-1/4">
				<label class="vs-input--label">Total</label>
				<vs-input class="w-full input-readonly" name="Value" v-model="total" readonly />
			</div>
		</div> -->

		<!-- <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx"
            />
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="handleShow(tr)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(tr.ListID)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div> -->

		<div class="vx-col w-full">
			<br />
			<!-- display: block -->
			<vs-button class="mb-2" v-on:click="openPrompt">
				Submit
			</vs-button>
		</div>

		<vs-prompt title="Confirmation" color="primary" @accept="handleSubmit" @cancel="closePrompt" @close="closePrompt" :buttons-hidden="false" :active.sync="promptSubmit">
			<div class="con-exemple-prompt">
				Shipment Cost
				<br />
				Are you sure to confirm <b>{{this.create.advanceCashDoc}}</b> shipment cost ?
				<br />
				<br />
				<!-- <vs-textarea v-model="notes"/> -->
			</div>
		</vs-prompt>
	</div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
// import { Validator } from 'vee-validate';

export default {
	components: {
		Datepicker,
	},
	props: {
		selected: Object,
	},
	created() { },
	data() {
		return this.initialState();
	},
	methods: {
		selectedInvoice(arg) {
			let remaining = parseFloat(arg.Remaining).toFixed(2)
			this.maxAdjustment = remaining
		},
		initialState() {
			return {
				inputs: [{
					expense_type: null,
					value: null,
					displayValue: null,
					evidence: null,
					reason: null,
					do_number: null,
					customer_number: null,
					files: null,
				}],
				fileAttachment: [],
				search: "",
				maxAdjustment: 0,
				option: {
					deliveryOrder: [],
					reason: [],
					customer: [],
					expenseType: []
				},
				header: [{
					text: "SO Data",
					sortable: false,
				},
				{
					text: "DO Data",
					sortable: false,
				},
				{
					text: "Customer",
					sortable: false,
				}],
				adjustment: {},
				adjustmentLine: {},
				create: {
					invoice: {},
					adjustmentValue: null,
					postingDate: null,
					coa: null,
					reason: "",
					costCenter: null,
					controlValue: null,
					cashValue: null,
					advanceCashDoc: null,
				},
				customNotif: {
					custom: {
						adjustmentValue: {
							required: "Adjustment value is empty",
						},
						reason: {
							required: () => "Reason is empty",
						},
						costCenter: {
							required: "Coct center value is empty",
						},
						postingDate: {
							required: "Posting date value is empty",
						},
					},
				},
				promptSubmit: false,
			};
		},
		mappingAttachment() {
			this.adjustment.Attachment.map(v => {
				this.fileAttachment.push({
					ListID: v.ListID,
					NameFile: v.NameFile,
					PathFile: v.PathFile,
				});
			})
			console.log(this.fileAttachment);
		},
		formatAndSetPrice(event, indextr) {
			// Remove existing commas to get the actual number
			let unformattedValue = event.target.value.replace(/,/g, '');

			// Update the actual value without commas
			this.inputs[indextr].value = unformattedValue;

			// Format the value with commas and update the display value
			this.inputs[indextr].displayValue = this.formatPrice(unformattedValue);
		},

		handleAttachment() {
			if (this.$refs.file.files.length > 0) {
				this.file = this.$refs.file.files;
				// this.fileAttachment = [];
				for (let i = 0; i < this.$refs.file.files.length; i++) {
					console.log(this.file[i]);
					// this.uploadData(i);
					this.fileAttachment.push({
						NameFile: this.file[i].name,
						File: this.file[i],
					});
				}
				document.querySelector("#fileInput").value = "";
			} else {
				this.$vs.notify({
					title: "Error",
					text: "Please select file to import.",
					color: "danger",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-check",
				});
			}
		},
		formatPrice(angka, prefix = "") {
			console.log("angka: " , angka.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","));
			return angka
				.toString()
				.replace(/,/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		paramUpload(array) {
			var form = new FormData();
			form.append("id", this.selected.ID);
			form.append("file", this.file[array]);
			return form;
		},
		uploadAttachmentAfter(i, id) {
			// this.$vs.loading();
			console.log(this.fileAttachment,"upload");
			if (this.fileAttachment.length == 0) {
				this.handleClose()
				this.$emit("closeDetail", true);
				this.$vs.loading.close();
				return;
			}
			if (this.fileAttachment.length < i) {
				this.handleClose()
				this.$emit("closeDetail", true);
				this.$vs.loading.close();
				return;
			}
			var form = new FormData();
			form.append("id", id);
			form.append("file", this.fileAttachment[i].File);
			this.$http
				.post("/api/v1/uang-jalan/upload-file", form, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					this.uploadAttachmentAfter(++i, id);
					this.$vs.loading.close();
					this.handleClose()
				})
				.catch((error) => {
					this.uploadAttachmentAfter(++i, id);
					this.handleClose()
				});
			// })
		},
		addField() {
			this.inputs.push({
				expense_type: null,
				value: null,
				displayValue: null,
				evidence: null,
				reason: null,
				do_number: null,
				customer_number: null,
				files: null,
			});
			console.log(this.inputs);
		},
		removeField(index) {
			this.inputs.splice(index, 1);
		},
		handleShow(file) {
			let objectURL;
			console.log(file);
			objectURL = URL.createObjectURL(file.File);

			// link.download = file.name; // this name is used when the user downloads the file
			var href = objectURL;
			window.open(href, "_blank").focus();
		},
		getGeneralForm() {
			const params = {
				id: this.selected.shipment_cost_id
			}
			console.log(params)
			this.$http
				.get("api/v1/uang-jalan/get-data", {
					params: params
				})
				.then((resp) => {
					if (resp.code == 200) {
						this.option.deliveryOrder = resp.data.list_do
						this.option.expenseType = resp.data.expense_type
						this.option.reason = resp.data.reason
						this.option.customer = resp.data.list_customer
						console.log(this.option.deliveryOrder, "DO");
						console.log(this.option.expenseType, "ET");
						
						console.log(this.selected, "selected");
						if (this.selected.driver_type === '' || this.selected.driver_type === null || this.selected.driver_type.toLowerCase() != "internal") {
							let filteredData = resp.data.expense_type.filter( x => x.description.toLowerCase().includes("biaya sewa"))

							console.log(filteredData, "filtered");
							this.inputs[0].expense_type = filteredData
						}
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get Invoice option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
			// this.getOptionCoa();
			// this.getOptionCostCenter();
			if (this.selected.shipment_cost_code != null) {
				this.create.advanceCashDoc = this.selected.shipment_cost_code
				this.create.cashValue = this.formatPrice(
					this.selected.advance_cash_value.toString()
				)
				this.create.postingDate = this.selected.shipment_plan_date

				var accountingData = JSON.parse(this.selected.accounting_data)
				console.log(accountingData, "ADA DISINI");
				this.create.transactionRef = accountingData.trans_ref
				this.create.transactionDate = accountingData.trans_date
				this.create.transactionValue = accountingData.trans_val
			}
		},
		handleFileUpload(index, event) {
			// this.file = event.target.files;
			console.log(event.target.files);
			const files = event.target.files;
			for (let i = 0; i < files.length; i++) {
				this.inputs[index].files = {
					NameFile: files[i].name,
					File: files[i],
				};
			}
			console.log(this.inputs, "this.inputs.files");
		},
		openPrompt() {
			this.promptSubmit = true;
		},
		closePrompt() {
			this.promptSubmit = false;
		},
		handleSubmit() {
			console.log(this.inputs, "ada disini");
			this.$validator.validateAll().then((result) => {
				console.log(this.selected, "ada disini");
				if (result) {
					this.$vs.loading();
					// const params = {
					// 	settlement_date: moment(
					// 		this.create.settlementDate == "" ? new Date() : this.create.settlementDate
					// 	),
					// 	id: this.selected.ID,
					// 	status: 4,
					// 	// invoice: this.create.invoice,
					// 	// adjustment_value: parseFloat(this.create.adjustmentValue.toString().replace(/,/g, "")),
					// 	// coa: this.create.coa,
					// 	// transaction_type: '01',
					// 	// source_of: 0,
					// 	// reason: this.create.reason,
					// 	// cost_center: this.create.costCenter,
					// 	// amount: parseFloat(this.create.cashValue.toString().replace(/,/g, "")),
					// };
					var form = new FormData();
					form.append("id", this.selected.shipment_cost_id);
					form.append("status", 4) 
					form.append("line", this.inputs.length)
					form.append("settlement_date", moment(
						this.create.settlementDate == "" ? new Date() : this.create.settlementDate
					))
					
					let do_number = []
					let customer_number = []
					let reason = []
					let value = []
					let evidance_text = []
					let expense_type = []

					this.inputs.map((v, i) => {
						expense_type.push(v.expense_type)
						value.push(v.value)
						reason.push(v.reason)
						evidance_text.push(v.evidence_text)
						do_number.push(v.do_number)
						customer_number.push(v.customer_number)

						this.fileAttachment.push(v.files)
					})
					form.append(`expense_type`, JSON.stringify(expense_type))
					form.append(`value`, JSON.stringify(value))
					form.append(`reason`, JSON.stringify(reason))
					form.append(`evidance_text`, JSON.stringify(evidance_text))
					form.append(`do_number`, JSON.stringify(do_number))
					form.append(`customer_number`, JSON.stringify(customer_number))
					console.log(form);
					console.log(this.inputs);
					console.log(value, 'this.fileAttachment')
					this.$http
						.post("/api/v1/uang-jalan/update", form, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						})
						.then((result) => {
							this.$vs.loading.close();
							this.handleClose()
							if (result.code == 200) {
								// this.handleClose()
								this.$vs.notify({
									title: "Success",
									text: "",
									color: "success",
									position: "top-right",
									iconPack: "feather",
									icon: "icon-check",
								});
								this.uploadAttachmentAfter(0, result.data.shipmentCost.ID);
								// this.handleClose()
							} else {
								this.$vs.notify({
									title: "Error",
									text: result.message,
									color: "danger",
									position: "top-right",
									iconPack: "feather",
									icon: "icon-check",
								});
							}
							this.handleClose();
						})
						.catch((e) => { });
				} else {
					this.$vs.notify({
						title: "Error",
						text: this.errors.all().join("\n"),
						color: "danger",
						position: "top-right",
						iconPack: "feather",
						icon: "icon-alert-circle",
					});
				}
			});
		},
		handleClose() {
			window.scrollTo(0, 0);
			this.$emit("closeDetail", true);
		},
		getOptionInvoice(query) {
			if (query.length <= 2) {
				return;
			}
			this.$vs.loading();
			this.$http
				.get("api/v1/invoice/data-table", {
					params: {
						search: query,
						length: 10,
						page: 1,
						order: "asc",
						sort: "code",
						tax_type_id: 2,
						// territory_id: this.selected.territory.ID,
						status: 3,
					},
				})
				.then((resp) => {
					if (resp.code == 200) {
						this.option.invoice = resp.data.invoices;
						console.log(this.option.invoice);
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get Invoice option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
		},
		selectedStep() {
			const additional = this.adjustment.AdditionalCoa[0]
			const coa = {
				ID: additional.CoaID,
				Name: additional.CoaName,
				Code: additional.CoaCode,
			}
			this.create.coa = coa
			this.option.coa = []
			this.option.coa.push(coa)

			const costCenter = {
				ID: additional.CostCenterId,
				Name: additional.CostCenterName,
				Code: additional.costCentertpCode,
			}

			this.create.costCenter = costCenter

			this.create.postingDate = this.adjustment.PostingDate
			this.create.reason = this.adjustment.Note
			this.create.ID = this.adjustment.ID
			this.create.adjustmentValue = this.formatPrice(this.adjustmentLine[0].AdjustmentValue)
		},
		findAdjustment(ID) {
			this.$vs.loading();
			this.$http
				.get(`api/v1/finance/adjustment/${ID}`)
				.then((resp) => {
					if (resp.code == 200) {
						this.adjustment = resp.data.records;
						this.adjustmentLine = resp.data.lines;
						this.create.invoice = resp.data.invoice[0];
						this.selectedInvoice(this.create.invoice)
						this.selectedStep()
						this.mappingAttachment()
						console.log(resp.data.invoice[0], "invoice");
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get Adjustment option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
		},
		onChangeCoa() {
			if (this.create.coa.Code.slice(0, 1) == "7") {
				console.log(this.option.costCenter);

				let costCenter = this.option.costCenter.filter(v => v.Code == "OTH")
				console.log(costCenter, "OTH");

				this.create.costCenter = costCenter[0]
			}
		}
	},
	mounted() {
		console.log(this.selected)
		// this.findAdjustment(this.selected.ID)
		this.$validator.localize("en", this.customNotif);
		this.getGeneralForm();
	},
	computed: {
		total() {
			console.log("input.value : ", this.inputs);
			const totalValue = this.inputs.reduce((sum, input) => {
				if (input.value != null && input.value != 0) {
					input.value = input.value.replace(/,/g, "")
				}
				const value = parseFloat(input.value) || 0;
				return sum + value;
			}, 0);
			return this.formatPrice(totalValue);
		}
	},
	watch: {
		"create.adjustmentValue"() {
			let adjustment = this.create.adjustmentValue.toString().replace(/[^.\d]/g, "").toString()
			let controlValue = this.maxAdjustment - adjustment
			this.create.controlValue = this.formatPrice(parseFloat(controlValue.toString()).toFixed(2))
		},
		"selected.ID"() {
			console.log(this.selected, "watch")
			this.findAdjustment(this.selected.ID)
		}
	},
};
</script>
<style scoped>
.pagination {
	display: flex;

	margin: 0.25rem 0.25rem 0;
}

.pagination button {
	flex-grow: 1;
}

.pagination button:hover {
	cursor: pointer;
}

.border-danger {
	border: 1px solid rgba(var(--vs-danger), 10) !important;

	border-radius: 5px;
}
</style>
